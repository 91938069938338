<template>
  <div class="discount">
    <h4>지금 결제하면 20% 할인</h4>
   </div>
  
</template>

<script>
export default {
    name : 'Discount',
    data(){
        return{
            
        }
    }
}
</script>

<style>

</style>