<template>
    
    <div class="black-bg">
        <div class="white-bg">
            <img :src="rooms[detailModal].image" class="room-img-dt">  
            <h4>{{ rooms[detailModal].title }}</h4>
            <p>{{ rooms[detailModal].content }}</p>
            <p>{{month}} 개월 선택 :  ₩{{ formatPrice(rooms[detailModal].price * month) }} 원</p>
            <input class="input-st" v-model.number="month"> <button @click="month++">+</button> <button @click="month--">-</button><p></p>
            <button @click="$emit('closeModal')">닫기</button>
        </div>
    </div>

</template>

<script>
export default {
    name: 'modal',
    props : {
        detailModal : Number,
        rooms : Array,
    }, 
    watch : {
        month(a){
            if(isNaN(a)){
                alert('문자입력했습니다')
                this.month = 1
            } else {
                if (a > 13){ 
                alert('13보다큽니다')
                this.month = 1
                }
                
            } 
        }  
    },
    data(){
        return {
            month : 1,
        }
    },
    methods:{
    
    formatPrice(value) {
        if (!value) return '0';
    return new Intl.NumberFormat().format(value);
    },
    }

}
</script>

<style>
.input-st{
    width: 50px;
    padding: 5px;
    border-radius: 6px;
    border-style: solid;
    border-color: #b1b1b1;
    border-width: 1px;
    
}

</style>