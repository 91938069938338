<template>

<div class="menu">
  <a v-for="navMenu in dataNavMenu" :key="navMenu">{{ navMenu }}</a>
</div>


<Discount v-if="showDiscount == true"/>

<p><button @click="priceSort">가격순정렬</button></p>

<Transition name="fade">
  <div v-if="isModal">  
    <modal :detailModal="detailModal" :rooms="rooms" @closeModal="isModal = false"/>
  </div>
</Transition>

<!-- <div class="black-bg" v-if="isModal">
    <div class="white-bg">
      <img :src="rooms[detailModal].image" class="room-img-dt">  
      <h4>{{ rooms[detailModal].title }}</h4>
      <p>{{ rooms[detailModal].content }}</p>
      <p>₩{{ formatPrice(rooms[detailModal].price) }} 원</p>
      <input> <p></p>
      <button @click="isModal=false">닫기</button>
    </div>
  </div> -->





  <div v-for="(a,i) in rooms" :key="i">
    <Card 
    @openModal = "isModal = true; detailModal=$event"
    v-if="rooms.length > 1"
    :원룸="rooms[i]"/> <!--  <Card v-bind:원룸="rooms[1]" /> -->
    
  </div>
    





</template>

<script>
import axios from 'axios';
import Discount1 from './Discount.vue';
import Card from './Card.vue';
import modal from './modal.vue';


export default {
  name: 'App',
  data(){
    return{
      showDiscount : true,
      detailModal : 0,
      rooms: [],
      isModal : false,
      신고수 : [0, 0, 0],
      dataNavMenu : ['Home', 'Shop', 'About'],
      price1 : 60,
      price2 : 70,
      product : ['역삼동원룸','천호동원룸','마포구원룸'],
    }
  }, 

  methods: {
    formatPrice(value) {
      if (!value) return '0';
      return new Intl.NumberFormat().format(value);
    },
    priceSort(){
      this.rooms.sort(function(a,b){
        return a.price - b.price
      })
    }
  },
  mounted(){ //라이프사이클 다 불러져왔을때?
    setTimeout(() => {
      this.showDiscount = false;
    }, 2000);
  },

  components: {
    Discount : Discount1,
    Card : Card,
    modal : modal,
    
  },

  created() { //라이프사이클 html 그리기 전?
    axios.get('/api/rooms')
      .then(response => {
        this.rooms = response.data;

      })
      .catch(error => {
        console.log(error);
      });
  }
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;

}

/* .fade-enter-from{
  transform: translateY(-1000px);
}
.fade-enter-active{
  transition: all 1s;
}
.fade-enter-to{
  transform: translateY(0px);
} */

.fade-enter-from{
  opacity: 0;
}
.fade-enter-active{
  transition: all 1s;
}
.fade-enter-to{
  opacity: 1;
}

.fade-leave-from{
  opacity: 1; 
  /* leave는 거꾸로 */
}
.fade-leave-active{
  transition: all 1s;
}
.fade-leave-to{
  opacity: 0;
}

body{
  margin: 0;
}

div {
  box-sizing: border-box;
}

.black-bg{
  width: 100%; height: 100%;
  background: rgba(0, 0, 0, 0.5);
  position: fixed; padding: 20px;
}

.white-bg{
  width: 100%; 
  background: white;
  border-radius: 8px;
  padding: 20px;
}


.menu{
  background: darkslateblue;
  padding: 15px;
  border-radius: 5px;

}

.menu a {

  color: white;
  padding: 10px;

}

.room-img {
  width: 100%;
  margin-top: 40px;
}

.room-img-dt {
  width: 200px;
  margin-top: 40px;
}

.discount {
  background: #eee;
  padding: 10px;
  margin: 10px;
  border-radius: 5px;
}

</style>
